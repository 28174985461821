import { signal, computed, effect } from "@preact/signals-core";
import { getItem } from "./stores/chats/chatsStore.tools";

export function isSupported(getStorage) {
  try {
    const key = "__some_random_key_you_are_not_going_to_use__";
    getStorage().setItem(key, key);
    //getStorage().removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}
const TOKEN_KEY = "token";
const ID_KEY = "id";
const FOUNDER_ID_KEY = "founder_id";
const PROGRAMME_ID_KEY = "programme_id";
const REFRESH_TOKEN_KEY = "refresh_token";
const EXP_DATE = "exp_date";
const THEME_MODE = "theme_mode";
const SEED_FLAG = "seed_flag";
const USER_OBJECT_KEY = "user";
const FOUNDER_KEY = "founder";
const COHORT_KEY = "cohort";
const LAST_PROMPT_TIME_KEY = "last_prompt_timestamp";
const LAST_IN_SYNC_CALENDAR = "last_in_sync_calendar";
const PROGRAMME_OBJECT_KEY = "programme";
const LAST_REMOTE_EVENTS_CHECKPOINT_KEY = "last_events_checkpoint";
// const LAST_REMOTE_CALENDAR_CHECKPOINT_KEY = "last_calendar_checkpoint"
const LAST_REMOTE_STARTUPS_CHECKPOINT_KEY = "last_startups_checkpoint";
const LAST_REMOTE_SESSIONS_CHECKPOINT_KEY = "last_sessions_checkpoint";
const LAST_REMOTE_MENTORS_CHECKPOINT_KEY = "last_mentors_checkpoint";
const LAST_REMOTE_RESOURCES_CHECKPOINT_KEY = "last_resources_checkpoint";
const FIRST_PAINT_REPLICATION_KEY = "first_paint_replication";

const RESTRICT_SESSIONS_KEY = "restrict_booking_sessions";
const RESTRICTED_SESSIONS_KEY = "restricted_booking_sessions";
const DELAY_REFRESH_PAGE_KEY = "delay_refresh_page";
const REFRESH_PAGE_KEY = "refresh_page";

function createAuthStore() {
  //const cohortDb = signal(null);

  const firstPaintReplication = signal(
    localStorage.getItem(FIRST_PAINT_REPLICATION_KEY) || ""
  );

  // const lastRemoteCalendarCheckpoint = signal(localStorage.getItem(LAST_REMOTE_CALENDAR_CHECKPOINT_KEY) || '');
  const lastRemoteSessionsCheckpoint = signal(
    getItem(LAST_REMOTE_SESSIONS_CHECKPOINT_KEY, "")
  );
  const lastRemoteMentorsCheckpoint = signal(
    getItem(LAST_REMOTE_MENTORS_CHECKPOINT_KEY, "")
  );
  const lastRemoteResourcesCheckpoint = signal(
    getItem(LAST_REMOTE_RESOURCES_CHECKPOINT_KEY, "")
  );
  const lastRemoteEventsCheckpoint = signal(
    getItem(LAST_REMOTE_EVENTS_CHECKPOINT_KEY, "")
  );
  const lastRemoteStartupsCheckpoint = signal(
    getItem(LAST_REMOTE_STARTUPS_CHECKPOINT_KEY, "")
  );

  const token = signal(localStorage.getItem(TOKEN_KEY) || "");
  const userObject = signal(getItem(USER_OBJECT_KEY, ""));
  const expDate = signal(localStorage.getItem(EXP_DATE) || "");
  const userId = signal(localStorage.getItem(ID_KEY) || "");
  const founderId = signal(localStorage.getItem(FOUNDER_ID_KEY) || "");
  const founderObject = signal(getItem(FOUNDER_KEY, ""));
  const programmeId = signal(localStorage.getItem(PROGRAMME_ID_KEY) || "");
  const userRole = computed(() => userObject.value && userObject.value.role);
  const getComputedId = computed(() =>
    userRole.value === "startup" ? founderId.value : userId.value
  );
  const getStartupId = computed(() => userId.value);
  const hasToken = computed(() => token.value !== null);
  const cohort = signal(getItem(COHORT_KEY, ""));
  const programmeObject = signal(getItem(PROGRAMME_OBJECT_KEY) || "");
  const userLastPrompt = signal(
    localStorage.getItem(LAST_PROMPT_TIME_KEY) || ""
  );
  const userActiveStatus = signal(null);
  const lastInSyncCalendar = signal(
    localStorage.getItem(LAST_IN_SYNC_CALENDAR) || ""
  );
  const systemForms = signal(null);
  const isUserLoading = signal(false);
  const computedUserProgrammeMinutes = computed(() => {
    let minutes = founderObject.value?.mentoringProgrammeMinutes.find(
      (item) => item.programme.id === programmeId.value
    )?.availableMinutes;
    if (!minutes) {
      return 0;
    } else return minutes;
  });
  const computedActiveProgramme = computed(() =>
    userObject.value?.programmes.find(
      (item) => item.programme.id === authStore.programmeId.value
    )
  );
  const founderAllowanceType = computed(
    () =>
      computedActiveProgramme.value?.programme?.settings
        ?.founderAllowanceType || "programme"
  );
  const mentorAllowanceType = computed(
    () =>
      computedActiveProgramme.value?.programme?.settings?.mentorAllowanceType
  );
  const computedUserProgrammeTopicMinutes = computed(() => {
    if (founderObject.value?.mentoringProgrammeTopicMinutes?.length > 0) {
      return founderObject.value?.mentoringProgrammeTopicMinutes;
    } else return [];
  });

  const restrictBookingSessions = signal(
    JSON.parse(localStorage.getItem(RESTRICT_SESSIONS_KEY)) || ""
  );

  const restrictedBookingSessions = signal(
    JSON.parse(localStorage.getItem(RESTRICTED_SESSIONS_KEY)) || []
  );

  const delayRefreshPage = signal(
    JSON.parse(localStorage.getItem(DELAY_REFRESH_PAGE_KEY)) || false
  );

  const refreshPage = signal(
    JSON.parse(localStorage.getItem(REFRESH_PAGE_KEY)) || false
  );

  const firstPaintReplicationDispose = effect(() => {
    localStorage.setItem(
      FIRST_PAINT_REPLICATION_KEY,
      firstPaintReplication.value
    );
  });

  const sessionsCheckpointDispose = effect(() => {
    localStorage.setItem(
      LAST_REMOTE_SESSIONS_CHECKPOINT_KEY,
      JSON.stringify(lastRemoteSessionsCheckpoint.value)
    );
  });

  const mentorsCheckpointDispose = effect(() => {
    localStorage.setItem(
      LAST_REMOTE_MENTORS_CHECKPOINT_KEY,
      JSON.stringify(lastRemoteMentorsCheckpoint.value)
    );
  });

  const resourcesCheckpointDispose = effect(() => {
    localStorage.setItem(
      LAST_REMOTE_RESOURCES_CHECKPOINT_KEY,
      JSON.stringify(lastRemoteResourcesCheckpoint.value)
    );
  });

  const eventsCheckpointDispose = effect(() => {
    localStorage.setItem(
      LAST_REMOTE_EVENTS_CHECKPOINT_KEY,
      JSON.stringify(lastRemoteEventsCheckpoint.value)
    );
  });

  const startupsCheckpointDispose = effect(() => {
    localStorage.setItem(
      LAST_REMOTE_STARTUPS_CHECKPOINT_KEY,
      JSON.stringify(lastRemoteStartupsCheckpoint.value)
    );
  });

  const userIdDispose = effect(() => {
    localStorage.setItem(ID_KEY, userId.value);
    //  cohortDb.value?.addLocalDocument(ID_KEY,userId.value)
    //  cohortDb.value?.addLocalDocument("userId",userId.value)
  });

  const programmeIdDispose = effect(() => {
    localStorage.setItem(PROGRAMME_ID_KEY, programmeId.value);
    // cohortDb.value?.addLocalDocument(PROGRAMME_ID_KEY,programmeId.value)
  });
  const tokenDispose = effect(() => {
    localStorage.setItem(TOKEN_KEY, token.value);
    // cohortDb.value?.addLocalDocument("authtoken", token.value)
    // cohortDb.value?.addLocalDocument(TOKEN_KEY, token.value)
  });

  const expDateDispose = effect(() => {
    localStorage.setItem(EXP_DATE, expDate.value);
    // cohortDb.value?.addLocalDocument(EXP_DATE,expDate.value)
  });

  const userObjectDispose = effect(() => {
    localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(userObject.value));
    // cohortDb.value?.addLocalDocument(USER_OBJECT_KEY, userObject.value)
  });

  const cohortDispose = effect(() => {
    localStorage.setItem(COHORT_KEY, JSON.stringify(cohort.value));
    // cohortDb.value?.addLocalDocument(COHORT_KEY,cohort.value)
  });

  const programmeObjectDispose = effect(() => {
    localStorage.setItem(
      PROGRAMME_OBJECT_KEY,
      JSON.stringify(programmeObject.value)
    );
    // cohortDb.value?.addLocalDocument(PROGRAMME_OBJECT_KEY,programmeObject.value)
  });
  const systemFormsDispose = effect(() => {
    //cohortDb.value?.addLocalDocument("system-forms",systemForms.value)
  });

  const founderObjectDispose = effect(() => {
    localStorage.setItem(FOUNDER_KEY, JSON.stringify(founderObject.value));
    //cohortDb.value?.addLocalDocument(FOUNDER_KEY,founderObject.value)
  });

  const founderIdDispose = effect(() => {
    localStorage.setItem(FOUNDER_ID_KEY, founderId.value);
    //cohortDb.value?.addLocalDocument(FOUNDER_ID_KEY,founderId.value)
  });

  const lastInSyncCalendarDispose = effect(async () => {
    localStorage.setItem(LAST_IN_SYNC_CALENDAR, lastInSyncCalendar.value);
    //cohortDb.value?.addLocalDocument(LAST_IN_SYNC_CALENDAR,lastInSyncCalendar.value)
  });

  console.log("authStore: userActiveStatus", userActiveStatus);
  if (userActiveStatus.value === null) {
    console.log(
      "authStore: userActiveStatus.value === null",
      userActiveStatus.value
    );
  }
  const shouldShowUserPrompt = computed(() => {
    console.log("userActiveStatus.value", userActiveStatus.value);
    if (userActiveStatus.value === true) {
      const now = new Date().getTime();
      const lastPrompt = userLastPrompt.value;
      const diff = now - lastPrompt;
      console.log(
        '"prompter: check for shouldShowUserPrompt, if dif > 600000 -> will be shown, diff: ',
        diff
      );
      return diff > 600000;
    } else {
      return false;
    }
  });

  // to check the auth store user value, when window "activity" status is changed
  const checkUserValue = effect(() => {
    console.log(
      "authStore (checkUserValue): User (window) status is changed: ",
      userActiveStatus.value
    );

    // if ids are diff -> update the values
    let localStorageId = localStorage.getItem(ID_KEY);
    let authStoreId = userId.value;

    /*
    console.log(
      "authStore (checkUserValue): ID FROM LOCAL STORAGE:",
      localStorageId
    );
    console.log("authStore (checkUserValue): ID FROM AUTH STORE:", authStoreId);

    console.log(
      "authStore (checkUserValue): compare id:",
      authStoreId === localStorageId
    );

    console.log(
      "authStore (checkUserValue): user FROM LOCAL STORAGE:",
      getItem(USER_OBJECT_KEY, "")
    );
    console.log(
      "authStore (checkUserValue): user FROM AUTH STORE:",
      userObject.value
    );

    console.log(
      "authStore (checkUserValue): token FROM LOCAL STORAGE:",
      localStorage.getItem(TOKEN_KEY, "")
    );
    console.log(
      "authStore (checkUserValue): token FROM AUTH STORE:",
      token.value
    );
    */

    // update value -> USER, ID, FOUNDER, TOKEN -> basically all the states 
    if (authStoreId !== localStorageId) {
      window.location.reload();
    }
  });

  return {
    mentorAllowanceType,
    computedUserProgrammeTopicMinutes,
    founderAllowanceType,
    computedActiveProgramme,
    computedUserProgrammeMinutes,
    firstPaintReplication,
    lastRemoteSessionsCheckpoint,
    lastRemoteMentorsCheckpoint,
    lastRemoteResourcesCheckpoint,
    lastRemoteEventsCheckpoint,
    lastRemoteStartupsCheckpoint,
    isUserLoading,
    expDate,
    systemForms,
    // cohortDb,
    userActiveStatus,
    shouldShowUserPrompt,
    programmeObject,
    userLastPrompt,
    cohort,
    getStartupId,
    getComputedId,
    userRole,
    hasToken,
    userObject,
    token,
    userId,
    founderId,
    founderObject,
    programmeId,
    lastInSyncCalendar,
    restrictBookingSessions,
    restrictedBookingSessions,
    delayRefreshPage,
    refreshPage,
  };
}
export const authStore = createAuthStore();

const saveToDb = async (key, data) => {
  try {
    const authStoredb = await getDB();
    await authStoredb.addLocalDocument(key, data);
  } catch (err) {
    console.log(err);
  }
};

export const saveAuthData = (token, expDate) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(EXP_DATE, expDate);
};

export const saveUserObject = (user) => {
  return localStorage.setItem(USER_OBJECT_KEY, JSON.stringify(user));
};
export const getUserObject = () => {
  let userObject = getItem(USER_OBJECT_KEY);
  return userObject;
};
export const getToken = () => {
  let TOKEN = localStorage.getItem(TOKEN_KEY);
  console.log("GETTING TOKEN", TOKEN);
  return TOKEN;
};

export const getExpDate = () => {
  return localStorage.getItem(EXP_DATE);
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const clearStorage = () => {
  localStorage.clear();
};

export const saveIdUser = (id) => {
  localStorage.setItem(ID_KEY, id);
  saveToDb(ID_KEY, id);
};

export const setUserLastPrompt = (timestamp) => {
  localStorage.setItem(LAST_PROMPT_TIME_KEY, timestamp);
  authStore.userLastPrompt.value = timestamp;
  console.log("timestamp", authStore.userLastPrompt.value);
};
export const getUserLastPrompt = () => {
  return localStorage.getItem(LAST_PROMPT_TIME_KEY);
};

export const seedDataFlag = (boolean) => {
  localStorage.setItem(SEED_FLAG, boolean);
};

export const saveFounderObjectLocalStorage = (founder) => {
  localStorage.setItem(FOUNDER_KEY, JSON.stringify(founder));
};
export const getFounderObjectLocalStorage = () => {
  return getItem(FOUNDER_KEY);
};
export const saveFounderId = (founderId) => {
  localStorage.setItem(FOUNDER_ID_KEY, founderId);
};
export const saveProgrammeId = (programmeId) => {
  localStorage.setItem(PROGRAMME_ID_KEY, programmeId);
};
export const getFounderId = () => {
  return localStorage.getItem(FOUNDER_ID_KEY);
};
export const getProgrammeId = () => {
  return localStorage.getItem(PROGRAMME_ID_KEY);
};

export const getSeedFlag = () => {
  return localStorage.getItem(SEED_FLAG);
};

export const getId = () => {
  return localStorage.getItem(ID_KEY);
};

export const saveThemeMode = (theme) => {
  localStorage.setItem(THEME_MODE, theme);
};

export const saveCohortObject = (cohort) => {
  localStorage.setItem(COHORT_KEY, JSON.stringify(cohort));
};
export const getCohortObject = () => {
  return getItem(COHORT_KEY);
};

export const saveRestrictBookingSessions = (value) => {
  localStorage.setItem(RESTRICT_SESSIONS_KEY, JSON.stringify(value));
};
export const getRestrictBookingSessions = () => {
  return localStorage.getItem(RESTRICT_SESSIONS_KEY);
};

export const saveRestrictedBookingSessions = (sessions) => {
  localStorage.setItem(RESTRICTED_SESSIONS_KEY, JSON.stringify(sessions));
};
export const getRestrictedBookingSessions = () => {
  return localStorage.getItem(RESTRICTED_SESSIONS_KEY);
};

export const saveDelayRefreshPage = (value) => {
  localStorage.setItem(DELAY_REFRESH_PAGE_KEY, JSON.stringify(value));
};
export const getDelayRefreshPage = () => {
  return localStorage.getItem(DELAY_REFRESH_PAGE_KEY);
};

export const saveRefreshPage = (value) => {
  localStorage.setItem(REFRESH_PAGE_KEY, JSON.stringify(value));
};
export const getRefreshPage = () => {
  return localStorage.getItem(REFRESH_PAGE_KEY);
};

export const getThemeMode = () => {
  return localStorage.getItem(THEME_MODE);
};

export const saveLastinSyncCalendar = (timestamp) => {
  localStorage.setItem(LAST_IN_SYNC_CALENDAR, timestamp);
};
export const getLastinSyncCalendar = () => {
  return localStorage.getItem(LAST_IN_SYNC_CALENDAR);
};

export const getLastRemoteEventsCheckpoint = () => {
  return getItem(LAST_REMOTE_EVENTS_CHECKPOINT_KEY);
};

export const saveLastRemoteEventsCheckpoint = (checkpoint) => {
  localStorage.setItem(
    LAST_REMOTE_EVENTS_CHECKPOINT_KEY,
    JSON.stringify(checkpoint)
  );
};
//startupscheckpoint

export const getLastRemoteStartupsCheckpoint = () => {
  return getItem(LAST_REMOTE_STARTUPS_CHECKPOINT_KEY);
};
export const saveLastRemoteStartupsCheckpoint = (checkpoint) => {
  localStorage.setItem(
    LAST_REMOTE_STARTUPS_CHECKPOINT_KEY,
    JSON.stringify(checkpoint)
  );
};
export const getLastRemoteMentorsCheckpoint = () => {
  return getItem(LAST_REMOTE_MENTORS_CHECKPOINT_KEY);
};
export const saveLastRemoteMentorsCheckpoint = (checkpoint) => {
  localStorage.setItem(
    LAST_REMOTE_MENTORS_CHECKPOINT_KEY,
    JSON.stringify(checkpoint)
  );
};

export const getLastRemoteSessionsCheckpoint = () => {
  return getItem(LAST_REMOTE_SESSIONS_CHECKPOINT_KEY);
};
export const setLastRemoteSessionsCheckpoint = (checkpoint) => {
  localStorage.setItem(
    LAST_REMOTE_SESSIONS_CHECKPOINT_KEY,
    JSON.stringify(checkpoint)
  );
};

export const getLastRemoteResourcesCheckpoint = () => {
  return getItem(LAST_REMOTE_RESOURCES_CHECKPOINT_KEY);
};
export const setLastRemoteResourcesCheckpoint = (checkpoint) => {
  localStorage.setItem(
    LAST_REMOTE_RESOURCES_CHECKPOINT_KEY,
    JSON.stringify(checkpoint)
  );
};

//MENTOR USERS RELATED INFO:
/*
const computedMentorSessionDuration = computed(()=>{
  if(authStore.userRole.value === "mentor"){
    return authStore.userObject.value?.calendly?.event?.duration || 30;
  }
})
*/

/*const computedMentorAvailableMinutes = computed(()=>{
  if(authStore.userRole.value === "mentor"){
    console.log("AUTH STORE PROGRAMMES",authStore.userObject.value?.programmes, authStore.userObject.value?.programmes?.find((item) => {return item.programme.id === authStore.programmeId.value }).totalMentorAvailableMinutes);
    authStore.userObject.value?.programmes?.find((item) => {return item.programme.id === authStore.programmeId.value })?.totalMentorAvailableMinutes;
  }
})

const computedMentorTotalMinutes = computed(()=>{
  if(authStore.userRole.value === "mentor"){
    return  authStore.userObject.value?.programmes?.find(
        (item) => {
          return item.programme.id === authStore.programmeId.value;
        }
    )?.totalMentorMinutes;
  }
})

const computedMentorTopicMinutes = computed(()=>{
  if(authStore.userRole.value === "mentor"){
    return authStore.userObject.value?.mentorMentoringProgrammeTopicMinutes?.filter(
        (item) => item.topic.programme.id === authStore.programmeId.value
    ) || [];
  }
})*/
